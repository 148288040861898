/*
 Default: #ed1c24;
 */ 

 h2, a:focus {
    color: #b33541;
}
.button, .center-wrap button button {
	border: 1px solid #b33541;
    background-color: #ff5656;
}
.plan .button:hover {
	border-color: #b33541;
}
.plan .button a, .glyph i {
	color: #b33541;
}

.plan.featured .button {
	background-color: #b33541;
}
.social-icons ul li a:hover {
	background-color: #b33541;
} 
.navbar-light .navbar-nav > .nav-item:hover, 
.navbar-light .navbar-nav > .nav-item:active {
	background-color: #b33541;
}

.owl-thumb-item i {
    color: #b33541;
}
.navbar-light .navbar-nav .nav-link.active{
	background-color: #b33541 !important;
}
.home-video-area::before {
	background-color: #ffffff;
}
.navbar-light.fixed-top, .search-modal,  .owl-thumb-item.active{
    background-color: #ff5656;
}

.owl-thumb-item:hover {
	background-color: #ffffff;
}
.navbar-light.fixed-top {
    background-color: #b33541 !important;
}

.filter-menu button.programs-filter-btn-active, .filter-menu button:hover {
	background-color: #b33541;
}

.work-details .icon li a, .team-box .title, .team-box .icon li a, .team-slides .owl-nav div {
	color: #b33541 !important;
}

.team-box .icon li a:hover {
	background: #b33541;
}

.date-box {
	background-color: #b33541;
}

.blog-item:hover .title-meta h2 a {
    color: #b33541;
}

.post-meta ul li a:hover {
	color: #b33541;
  }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	background-color: #b33541;
	border-color: #b33541;
}
.side-widget h3::before { 
	background: #b33541;
}
.search-form .btn-default {
	border-color: #b33541;
	background: #b33541;
}
.side-widget .list-group li a:hover {
	color: #b33541;
}
.single-post h4 a:hover {
	color: #b33541;
}
.list-tags li a:hover {
    background-color: #b33541;
}
.sharing-link ul li i:hover {
    background-color: #b33541;
}
.content-box:hover .box-icon-effect-1a .box-icon i {
    color: #b33541;
}
.work-details .box-content{
	background: #b33541;
}
.comments-title::before {
	background: #b33541;
}
.single-comment .reply:hover {
	background-color: #b33541;
}

.plus, .post-meta ul li .fa {
	color: #b33541;
} 
.plus:hover {
	color: #b33541;
}
.tab-menu ul li.active a {
	background-color: #b33541;
}
.plan.featured .listing-badges .featured {
	color: #b33541;
}
.plan.featured .plan-price, .content-box.color-effect-1, .footer  {
	background-color: #b33541;
}
.single-testimonial-item i, .partners-slides .owl-nav div {
    color: #b33541 !important;
}
.client-info h3, .testimonial-slides .owl-nav div {
	color: #b33541 !important;
 }
 .panel-title a:before, .panel-title a.collapsed:before {
	color: #b33541;
	border: 1px solid #b33541;
}
.social-icons.bottom ul li a {
	background-color: #b33541;
}
.social-icons.bottom ul li a:hover {
    color: #b33541;
}
.about-info i {
    color:#b33541;
}
.about-info:hover {
    background-color: #b33541;
}
.plan .btn-a {
    color: #b33541;
}